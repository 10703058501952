<template>
  <div class="mine page">
    <div class="bg-container page-bg" :style="{ 'background-image': `url(${$store.getters.getBaseInfo.center_bg})` }">
      <div class="bg-wrapper"></div>
    </div>

    <!-- <div class="page-bg"></div> -->
    <div class="wrapper">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
        :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <div class="header" :style="{ 'background': $store.getters.getBaseInfo.center_bg ? 'none' : 'background: #000)' }">
          <van-nav-bar class="nav-bar"
            :style="{ 'background': $store.getters.getBaseInfo.center_bg ? 'none' : 'background: #000' }">
            <template #right>
              <van-icon name="setting-o" @click="showSetting()" color="#fff" />
            </template>
          </van-nav-bar>
          <div class="user-wrapper" @click="doLogin()">
            <van-image round class="user_img" :src="this.userInfo.header_img">
              <template v-slot:loading>
                <van-loading type="spinner" />
              </template>
            </van-image>
            <div class="login-content">
              <p class="login-btn">{{ this.userInfo.username }}
                <img class="login-vip" v-if="this.userInfo && this.userInfo.groupinfo"
                  :src="this.userInfo.groupinfo.image" alt="">
              </p>
              <p class="login-label" v-if="this.userInfo && this.userInfo.groupinfo">
                <van-progress :percentage="this.userInfo.groupinfo.rate" :show-pivot="false" stroke-width="8px"
                  color="linear-gradient(270deg, #fff, #EE8B35)" />
              </p>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="finance">
            <!-- <div class="finance-item" @click="doPay()">
              <div>
                <div style="text-align: center;">

                  <van-icon class="icon" style="" name="peer-pay" />
                </div>
                <div class="text">{{ $t("my.recharge") }}</div>

              </div>
            </div>
            <div class="line"></div> -->
            <div class="finance-item" @click="doWithdrawal()">
              <div>
                <div style="text-align: center;"><van-icon class="icon" name="idcard" /></div>
                <div class="text">{{ $t("my.withdraw") }}</div>
              </div>

            </div>
          </div>
          <div class="wallet">
            <div class="part-1 van-hairline--bottom" @click="$router.push({ path: '/Moneylog' });">
              <p class="flex-1 font-28 font-primary-color">{{ $t("my.my_balance") }}</p>
              <span class="font-28 font-gray">{{ $t("my.detail") }}</span>
              <van-icon class="font-gray" style="font-size: 28px" name="arrow" />
            </div>
            <div class="part-2">
              <p class="balance van-ellipsis">{{ this.userInfo.money }}</p>
              <!-- {{$t("my.balance")}} -->
              <span class="font-28 font-gray">({{ $t("my.balance") }})</span>
              <div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
            </div>

            <!-- <div class="part-2">
              <p class="balance van-ellipsis">{{ this.userInfo.point }}</p>
              <span class="font-28 font-gray"> {{ $t("my.point") }}</span>
              <div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
            </div> -->
          </div>
          <div :style="{ marginTop: menu_top + 'px' }" class="menu">
            <!-- <div class="menu-item" @click="$router.push({path:'/Personalreport'});">
                <van-image class="menu-item-icon" src="img/mine/baobiao.svg">
                  <template v-slot:loading>
                    <van-loading type="spinner"/>
                  </template>
                </van-image>
                <span class="menu-item-label" >{{$t("my.my_statement")}}</span>
              </div> -->
            <div class="menu-item" @click="$router.push({ path: '/Infomation' });">
              <van-image class="menu-item-icon" src="img/mine/user.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("my.personal_center") }}</span>
            </div>
            <!-- <div class="menu-item" @click="$router.push({path:'/RechargeRecord'});">
                <van-image class="menu-item-icon" src="img/mine/mingxi.svg">
                  <template v-slot:loading>
                    <van-loading type="spinner"/>
                  </template>
                </van-image>
                <span class="menu-item-label" >{{$t("my.account_detail")}}</span>
              </div> -->
            <div class="menu-item" @click="$router.push({ path: '/GameRecord' });">
              <van-image class="menu-item-icon" src="img/mine/youxi.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("my.task_record") }}</span>
            </div>
            <div class="menu-item" @click="toNotice()">
              <van-image class="menu-item-icon" src="img/mine/gonggao.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("my.information_announcement") }}</span>
            </div>
            <!-- <div class="menu-item" @click="toService()">
                <van-image class="menu-item-icon" src="img/mine/kefu_1.svg">
                  <template v-slot:loading>
                    <van-loading type="spinner"/>
                  </template>
                </van-image>
                <span class="menu-item-label" >{{$t("my.online_service")}}</span>
              </div> -->
          </div>
        </div>
      </van-pull-refresh>
    </div>

  </div>

</template>

<script>
import { ImagePreview } from 'vant';
import vip_detail from '@/assets/vip_detail.jpg'
export default {
  data() {
    return {
      userInfo: {
      },
      menu_top: 40,
      isLoading: false
    };
  },
  methods: {
    onImgPreView() {
      // 点击轮播图时,通过数据拿到当前索引,根据当前索引创建图片预览,设置默认图片
      ImagePreview({
        images: [vip_detail], //点击后的图片。
        startPosition: 0, //index默认为0，提供的起始位置
      });
    },
    refresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          // this.$toast(this.$t("reservation.refresh"));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },
    exit() {
      this.$toast(this.$t("my.finish_task"));
    },
    showSetting() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Setting' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    toNotice() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Notice' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.getUserInfo();
          // this.$toast(this.$t("reservation.refresh"));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },
    doLogin() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Infomation' });
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    doPay() {
      if (this.$store.getters.getBaseInfo.close_withdraw == 1) {
        this.$toast.fail(this.$t("setting.doPay"));
      } else {
        this.$router.push({
          name: 'Recharge',
          params: {
            'balance': this.userInfo.money
          }
        })
      }
    },
    doWithdrawal() {

      if (this.$store.getters.getBaseInfo.close_withdraw == 1) {
        this.$toast.fail(this.$t("setting.doWithdrawal"));
      } else {
        this.$http({
          method: 'get',
          url: 'user_get_bank'
        }).then(res => {
          if (res.data.is_bank) {
            this.$router.push("withdraw");
          } else {
            this.$router.push("Setbank");
            this.$toast.fail(this.$t("setting.set_bank"));
          }
        })
      }


    },
    toService() {
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail(this.$t("setting.forbid"));
      }
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t("video.account_out"));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    if (localStorage.getItem('token')) {
      this.getUserInfo();
    } else {
      this.userInfo.username = this.$t("setting.log_reg");
      this.userInfo.ip = this.$t("setting.more_service");
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style scoped lang="less">
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
}

.mine {
  position: relative;
  bottom: 10px;
  // background: #f2f2f5;
}

.mine .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 180px;
}

.nav-bar {
  background: #000;
}

.mine .header {
  background: #000;
  padding-bottom: 100px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}

.mine .header .van-nav-bar .van-icon {
  font-size: 45px;
}

.mine .header .user-wrapper {
  display: flex;
  align-items: center;
  margin: 0px 40px 0px 40px;

}

.mine .user_img {
  height: 130px;
  width: 130px;
}

::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}

::v-deep .van-image__error-icon {
  font-size: 70px;
}

.mine .header .user-wrapper .login-content {
  flex: 1;
  margin-left: 30px;
}

.mine .header .user-wrapper .login-content .login-btn {
  display: inline-block;
  font-size: 40px;
  line-height: 0px;
  color: #fff;
  display: flex;
  align-items: center;
}

.mine .header .user-wrapper .login-content .login-label {
  /* margin-top: -13px; */
  width: 60%;
  font-size: 28px;
  color: hsla(0, 0%, 100%, .6);

}

.mine .page-bg {
  height: 500px;
  background: linear-gradient(90deg, #EE8B35, #fff);
  z-index: 0;
  background-size: cover;

}

.mine .content {
  position: relative;
  padding: 10px 30px 30px;
  min-height: 500px;
  background-color: #000;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -55px;
  left: 30px;
  right: 30px;
  height: 120px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .finance .line {
  width: 3px;
  height: 40px;
  background-color: #ccc;
}

.mine .wrapper .content .finance .finance-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mine .wrapper .content .finance .finance-item .text {
  // margin-left: 30px;
  font-size: 30px;
  color: #000;
  font-weight: 500;
}

.mine .wrapper .content .finance .finance-item .icon {
  font-size: 50px;
}

.mine .wrapper .content .menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .menu .menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 130px;
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
  font-size: 26px;
  color: #868686;
  font-weight: 500;
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
  margin: 25px;
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}

.mine .wrapper .content .wallet {
  margin-top: 80px;
  padding: 0 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .wallet .part-1 {
  display: flex;
  align-items: center;
  height: 100px;
}

.mine .wrapper .content .wallet .font-primary-color {
  color: #000;
}

.font-gray {
  color: #868686;
}

.mine .wrapper .content .wallet .part-2 {
  display: flex;
  align-items: center;
  height: 150px;
}

.mine .wrapper .content .wallet .part-2 .balance {
  flex: 1;
  font-size: 60px;
  color: #EE8B35;
  font-weight: 700;
}

.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}

.mine .wrapper .content .wallet .part-2 .refresh-btn {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #fff;
}

.login-vip {
  width: 142px;
  height: 45px;
  margin-left: 0px;
  margin-bottom: -12px;
}
</style>
