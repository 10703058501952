<template>
    <div class="container page">
        <div class="header">
            <van-nav-bar title="MEMBER" class="nav-bar">
                <template #left>
                    <van-icon name="arrow-left" color="#fff" @click="back()" />
                </template>
            </van-nav-bar>
        </div>
        <div class="header_title" :class="'vip_' + id + 'bg'">
            <div class="header_title_name">
                {{ name }}
            </div>
            <template v-if="id == 0">

                <div class="header_title_lab">อายุการใช้งาน 3เดือน</div>
                <div class="header_title_c">
                    1. รับส่วนลด 20% เมื่อเปิดใช้งานบัตร2. ไม่จำกัดจำนวนครั้ง อายุการใช่งานบัตร 3เดือน
                    3. ลิขสิทธ์ของบัตรใบนี้เป็นของ CLUB X THAILAND เท่านั้น4. เปิดใช้งานครั้งเเรก -ฟรีคอล
                    เสียว- ไลท์สด1เดือน ฟรี Viagra 1 แฝง +ถุงยาง -นวดฟรี 2 ช.มไม่ต้องจ่ายเพิ่ม
                    (ต้องยืนยันตัว เเละเปิดการใช้งานบัตรMEMBERให้เรียบร้อย) **หมายเหตุ : ลูกค้าใหม่ สามารถ
                    ยื่นบัตรนี้เพื่อรับสิทธิ์โรงแรม/รีสอร์ท ( ฟรี 2วัน1คืน) + เครื่องดื่ม 1 สิทธิ และของแถมอีกมากมาย
                    (ครั้งแรก)
                </div>
            </template>
            <template v-if="id == 1">

                <div class="header_title_lab">อายุการใช้งาน 6เดือน</div>
                <div class="header_title_c">
                    1. รับส่วนลด 30% เมื่อเปิดใช้งานบัตร2. ไม่จำกัดจำนวนครั้ง อายุการใช่งานบัตร 6เดือน

                    3. ลิขสิทธ์ของบัตรใบนี้เป็นของ CLUB X THAILAND เท่านั้น4. เปิดใช้งานครั้งเเรก -ฟรีคอล
                    เสียว- ไลท์สด1เดือน ฟรี Viagra 1 แฝง +ถุงยาง -นวดฟรี 2 ช.มไม่ต้องจ่ายเพิ่ม

                    (ต้องยืนยันตัว เเละเปิดการใช้งานบัตรMEMBERให้เรียบร้อย) **หมายเหตุ : ลูกค้าใหม่ สามารถ
                    ยื่นบัตรนี้เพื่อรับสิทธิ์โรงแรม/รีสอร์ท ( ฟรี 2วัน1คืน) + เครื่องดื่ม 2 สิทธิ และของแถมอีก
                    มากมาย (ครั้งแรก)
                </div>
            </template>
            <template v-if="id == 2">

                <div class="header_title_lab">อายุการใช้งาน 1ปี</div>
                <div class="header_title_c">
                    1. รับส่วนลด 40% เมื่อเปิดใช้งานบัตร2. ไม่จำกัดจำนวนครั้ง อายุการใช่งานบัตร 1 ปีเต็ม

                    3. ลิขสิทธ์ของบัตรใบนี้เป็นของ CLUB X THAILAND เท่านั้น4. เปิดใช้งานครั้งเเรก -ฟรีคอล
                    เสียว- ไลท์สด1เดือน ฟรี Viagra 1 แฝง +ถุงยาง -นวดฟรี 2 ช.มไม่ต้องจ่ายเพิ่ม

                    (ต้องยืนยันตัว เเละเปิดการใช้งานบัตรMEMBERให้เรียบร้อย) **หมายเหตุ : ลูกค้าใหม่ สามารถ
                    ยื่นบัตรนี้เพื่อรับสิทธิ์โรงแรม/รีสอร์ท ( ฟรี 3วัน2คืน ) + เครื่องดื่ม 4สิทธิ และของแถมอีก
                    มากมาย (ครั้งแรก)
                </div>
            </template>
            <template v-if="id == 3">

                <div class="header_title_lab">อายุการใช้งาน ตลอดชีพ</div>
                <div class="header_title_c">
                    1. รับส่วนลด 50% เมื่อเปิดใช้งานบัตร 2. ไม่จำกัดจำนวนครั้ง อายุการใช่งานบัตร (ตลอด
                    ชีพ) 3. ลิขสิทธ์ของบัตรใบนี้เป็นของ CLUB X THAILAND เท่านั้น 4. เปิดใช้งานครั้งเเรก
                    -ฟรีคอลเสียว- ไลท์สดฟรี+ Viagra +ถุงยาง +นวดฟรี 5ช.ม ไม่ต้องจ่ายเพิ่ม ตลอดการใช้
                    งาน (ต้องยืนยันตัวตน เเละเปิดการใช้งานบัตรMEMBERให้เรียบร้อย) **หมายเหตุ : ลูกค้า
                    ใหม่ สามารถยื่นบัตรนี้เพื่อรับสิทธิ์โรงแรม/รีสอร์ท ( ฟรี 7วัน6คืน + เครื่องดื่ม 7 สิทธิ เเละ
                    ของเเถมอีกมากมาย(ครั้งแรก))
                </div>
            </template>
        </div>
        <template v-if="id == 0">
            <img src="@/assets/game/vip1_p1.png" style="width: 100%;" alt="">
        </template>
        <template v-if="id == 1">
            <img src="@/assets/game/vip2_p1.png" style="width: 100%;" alt="">
        </template>
        <template v-if="id == 2">
            <img src="@/assets/game/vip3_p1.png" style="width: 100%;" alt="">
        </template>
        <template v-if="id == 3">
            <img src="@/assets/game/vip4_p1.png" style="width: 100%;" alt="">
        </template>
        <div style="display: flex;" :class="'vip_' + id + 'bg'" :style="id==3|| id==2?'flex-direction: row-reverse;':''">
           

                <template v-if="id == 0">
                    <div style="width: 50%;">
                    <img src="@/assets/game/vip1_p2.png" style="width: 100%;" alt="">
                     </div>
                </template>

                <template v-if="id == 1">
                    <div style="width: 50%;">
                    <img src="@/assets/game/vip2_p2.png" style="width: 100%;" alt="">
                     </div>
                </template>

                <template v-if="id == 2">
                    <div style="width: 33%;">
                    <img src="@/assets/game/vip3_p2.png" style="width: 100%;" alt="">
                     </div>
                </template>

                <template v-if="id == 3">
                    <div style="width: 33%;">
                    <img src="@/assets/game/vip4_p2.png" style="width: 100%;" alt="">
                     </div>
                </template>
            <div class="vip_end" style="width: 70%;" v-if="id == 0">
                <div class="vip_end_title">สิทธิประโยชน์ สำหรับสมาชิกใหม่</div>
                <div class="vip_end_content">เงื่อนไขการใช้เมมเบอร์
                    อายุสมาชิกมีกำหนดระยะ 3เดือน นับตั้งแต่วันที่สมัคร
                    สิทธิ์พิเศษที่สมาชิกที่ได้รับสามารถเปลี่ยนเป็นเงินได้
                    โปรโมชั่นขงทางคลับClub X THAILAND ไม่สามารถใช่รวมกับเมมเบอร์ได้
                    ขอสงวนสิทธิ์ในการเปลื่ยนเเปลงโดยที่ไม่ต้องแจ้งให้ทราบล้วงหน้า
                </div>
            </div>



            <div class="vip_end" style="width: 70%;" v-if="id == 1">
                <div class="vip_end_title">สิทธิประโยชน์ สำหรับสมาชิกใหม่</div>
                <div class="vip_end_content">เงื่อนไขการใช้เมมเบอร์
                    อายุสมาชิกมีกำหนดระยะ 6เดือน นับตั้งแต่วันที่สมัคร
                    สิทธิ์พิเศษที่สมาชิกที่ได้รับสามารถเปลี่ยนเป็นเงินได้
                    VOUCHER

                    โปรโมชั่นขงทางคลับClub X THAILAND ไม่สามารถใช่รวมกับเมมเบอร์ได้
                    อายุการใช้งาน 6เดือน

                    ขอสงวนสิทธิ์ในการเปลื่ยนเเปลงโดยที่ไม่ต้องแจ้งให้ทราบล้วงหน้า</div>
            </div>

            <div class="vip_end" style="width: 70%;" v-if="id == 2">
                <div class="vip_end_title">สิทธิประโยชน์ สำหรับสมาชิกใหม่</div>
                <div class="vip_end_content">เงื่อนไขการใช้เมมเบอร์
                    อายุสมาชิกมีกำหนดระยะ 1ปี นับตั้งแต่วันที่สมัคร
                    สิทธิ์พิเศษที่สมาชิกที่ได้รับสามารถเปลี่ยนเป็นเงินได้
                    VOUCHER

                    โรงแรม 5ดาว 3วัน2คืน

                    โปรโมชั่นขงทางคลับClub X THAILAND ไม่สามารถใช่รวมกับเมมเบอร์ได้
                    อายุการใช้งาน 1ปี

                    HOTEL ASOKE

                    Modern Hotel & Resort

                    ขอสงวนสิทธิ์ในการเปลื่ยนเเปลงโดยที่ไม่ต้องแจ้งให้ทราบล้วงหน้า</div>
            </div>

            <div class="vip_end" style="width: 70%;" v-if="id == 3">
                <div class="vip_end_title">สิทธิประโยชน์ สำหรับสมาชิกใหม่</div>
                <div class="vip_end_content">เงื่อนไขการใช้เมมเบอร์
                    อายุสมาชิกมีกำหนดระยะ ตลอดชีพ นับตั้งแต่วันที่สมัคร
                    สิทธิ์พิเศษที่สมาชิกที่ได้รับสามารถเปลี่ยนเป็นเงินได้
                    ขอสงวนสิทธิ์ในการเปลื่ยนเเปลงโดยที่ไม่ต้องแจ้งให้ทราบล้วงหน้า</div>
            </div>

        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            id: 0, name: ''
        };
    },
    methods: {
        back() {
            return window.history.back();
        },
    },
    created() {
        this.id = this.$route.query.id
        this.name = this.$route.query.name
    }
};
</script>
  
<style lang='less' scoped>
.header_title {
    padding: 30px;
}

.header_title_name {
    font-size: 32px;
    text-align: center;
}

.header_title_lab {
    text-align: center;
    margin: 10px 0;
}

.header_title_c {}

.vip_end {
    margin-left: 30px;
    padding: 30px 0;
}

.vip_end_title {
    font-size: 30px;
}

.vip_end_content {}

.vip_0bg {
    background: #A3A3A3;
    color: #000;
}

.vip_1bg {
    background: #D9B769;
    color: #000;
}

.vip_2bg {
    background: #1D3745;
    color: #fff;
}

.vip_3bg {
    background: #470838;
    color: #fff;
}
</style>
  