<template>
  <div class="convention-hall page">
    <van-nav-bar class="nav-bar" :title="lotteryitemIndex==5?'ติดต่อ': lotteryitemIndex == 2?'PACKAGE':$t('reservation.hall')" />
    <img src="@/assets/game/3_p1.png" style="width: 100%;" v-if="lotteryitemIndex == 2" alt="">
    <img src="@/assets/game/jiudian_p1.png" style="width: 100%;" v-if="lotteryitemIndex == 3" alt="">
    <div class="convention-item">
      <div class="left">
        <!-- <van-sidebar @change="onChange" v-model="activeKey">
        <van-sidebar-item v-for="(v,key) in lotteryitem" :key="key" :title="v.name" >333</van-sidebar-item>
      </van-sidebar> -->
        <div class="me-sidebar">
          <div class="me-sidebar-item" v-for="(item, index) in lotteryitem" :key="index"
            :class="lotteryitemIndex == index ? 'me-sidebar-item-active' : ''" @click="tuUrl(index)">
            <div>

              <img class="me-sidebar-item-img" :src="item.img_active" alt="" v-if="lotteryitemIndex == index">
              <img class="me-sidebar-item-img" :src="item.img" alt="" v-else>
              <div class="me-sidebar-item-name">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <template v-if="lotteryitemIndex == 0">
          <img src="@/assets/game/1_p4.png" style="width: 100%;" alt="">
          <img src="@/assets/game/1_p1.png" style="width: 94%;margin: 10px auto;display: flex;" alt="">
          <img src="@/assets/game/1_p2.png" style="width: 100%;" alt="">
          <img src="@/assets/game/1_p3.png" style="width: 100%;" alt="">
        </template>
        <template v-if="lotteryitemIndex == 1">
          <div class="record">
            <div class="period">
              <span class="period-number">{{ this.lottery.next_expect }}</span>
              <div class="next-number">
                <template v-if="time !== null">

                  <van-count-down :time="time" @finish="check()" />
                </template>
              </div>

            </div>
            <div class="checkedContent">

              <div class="options-bar">
                <div class="game">
                  <div class="linear-gradient"
                    style="background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));">
                  </div>
                  <div class="sumValueTwoSides">
                    <div class="rectangle large" style="position: relative;" :class="{ active: choose[v.type] }"
                      v-for="(v, key) in lottery_peilv_list" :key="key" @click="choosePlay(v.type, v.name);">
                      <div v-if="choose[v.type]" class="mask"><van-icon name="success" color="#fff" /></div>
                      <div class="wrapper" :style="{ 'background': `url(${v.img})`, 'background-size': '100% 100%' }">
                        <div class="content">
                        </div>
                      </div>
                      <p style="text-align: center;" class="name-text large">{{ v.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style="flex: 1;"></div>
              <div class="inpContent">
                <div>
                  <van-field :label-width="120" :label="$t('reservation.per_price')" v-model="money" class="amount-wrapper-inp" type="digit"
                    :placeholder="$t('reservation.price_place')" />
                  <van-field  :label-width="120" :label="$t('reservation.money')" :value="userInfo.money" disabled
                    class="amount-wrapper-inp" />


                  <van-button class="item sub-btn" :disabled="isDisabled" type="default" @click="doSub">{{
                    $t("reservation.submit") }}</van-button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="lotteryitemIndex == 2">
          <div class="VipList">
            
                <div class="jiud_title" style="font-size: 24px;">MEMBER</div>
            <van-grid :column-num="2" :border="false" >
              <van-grid-item v-for="(v, key) in VipList" :key="key" @click="toVip(key,v.name)">
                <span>{{ v.name }}</span>
              </van-grid-item>
            </van-grid>
            <img style="width: calc(100% - 10px);margin: 0 5px;" src="@/assets/game/bg2.jpg" alt="">
          </div>

        </template>
        <template v-if="lotteryitemIndex == 3">
          <div class="options-bar">
            <div class="game">
              <div class="linear-gradient"
                style="background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));">
              </div>
              <div v-for="(v, key) in jiudian_list" :key="key">
                <div class="jiud_title">{{ v.title }}</div>
                  <div class="sumValueTwoSides">
                    <div  v-for="(item, index) in v.list" :key="index" class="rectangle large" style="position: relative;background: #fff;width: 20%;">
                      <div class="wrapper" :style="{ 'background': `url(${item.img})`, 'background-size': '100% 100%' }" @click="openImg(item.img)">
                        <div class="content">
                        </div>
                      </div>
                      <div style="text-align: center;color: #000;" class="name-text large">{{ item.name }}</div>
                      <p style="text-align: center;color: #000;margin: 0;" class="name-text large">{{ item.lab }}</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="lotteryitemIndex == 4">
          <!-- <iframe src="https://pornhubapparel.com" style="height: 100%;width: 100%;" frameborder="0"></iframe> -->
        </template>
        <template v-if="lotteryitemIndex == 5">
          <div class="phone_btn">ติดต่อฝ่ายบริการ</div>
          <div class="phone_btn">
            <img style="width: 30px;" src="@/assets/game/phone.png" alt="">
            082-898-3984</div>
          <div class="phone_btn">
            <img  style="width: 30px;" src="@/assets/game/hulianw.png" alt="">https://xthailand.online/</div>
          <div class="phone_btn">
            <img style="width:30px;" src="@/assets/game/line.svg" alt="">@350uoeny</div>
          <img style="width: 40%;margin: 20px auto;text-align: center;display: flex;" src="@/assets/game/qrcode1.jpg" alt="">
          <div class="qrcode_txt">สแกนQR code</div>
        </template>
        <!-- <van-pull-refresh :border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')" :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <van-grid :column-num="2">
          <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
            <van-image class="game_item_img" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
            <span>{{v.name}}</span>
            <span>{{v.desc}}</span>
          </van-grid-item>
        </van-grid>
      </van-pull-refresh> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Toast,ImagePreview } from 'vant';

export default {
  data() {
    return {
      gameitem: "",
      lotteryitemIndex: 0,
      VipList: [{
        name: 'SILVER'
      }, {
        name: 'GOLD'
      }, {
        name: 'PLATINUM'
      }, {
        name: 'VIP PREMIUM'
      }],
      lotteryitem: [{
        img: require('@/assets/game/1.png'),
        img_active: require('@/assets/game/1_active.png'),
        name: 'เกี่ยวกับเรา'
      }, {
        img: require('@/assets/game/2.png'),
        img_active: require('@/assets/game/2_active.png'),
        name: 'บัตรกำนัล'
      }, {
        img: require('@/assets/game/3.png'),
        img_active: require('@/assets/game/3_active.png'),
        name: 'บัตรสมาชิก'
      }, {
        img: require('@/assets/game/4.png'),
        img_active: require('@/assets/game/4_active.png'),
        name: 'ห้องสวีท'
      }, {
        img: require('@/assets/game/5.png'),
        img_active: require('@/assets/game/5_active.png'),
        name: 'ร้านค้า'
      }, {
        img: require('@/assets/game/6.png'),
        img_active: require('@/assets/game/6_active.png'),
        name: 'ติดต่อเรา'
      }],
      isLoading: false,
      lottery: {},
      time: '',
      activeKey: 0,
      formData: [],
      lottery_peilv_list: {},
      shopchoose: this.$t("reservation.no_choose"),
      shopping: false,
      money: '',
      choose: {
        "bi": false,
        "sm": false,
        "si": false,
        "do": false,
        "3": false,
        "4": false,
        "5": false,
        "6": false,
        "7": false,
        "8": false,
        "9": false,
        "10": false,
        "11": false,
        "12": false,
        "13": false,
        "14": false,
        "15": false,
        "16": false,
        "17": false,
        "18": false,
      },
      gameList: [],
      userInfo: {},
      isDisabled: false,
      jiudian_list: [
        {
          title: 'ภาคเหนือ',
          list: [
            {
              img: require('@/assets/game/jiudian/1-1.jpg'),
              name: 'LE PATTA',
              lab:'เชียงราย'
            },
            {
              img: require('@/assets/game/jiudian/1-2.jpg'),
              name: 'TOLANI NORTHGATE',
              lab:'เชียงใหม่'
            },
            {
              img: require('@/assets/game/jiudian/1-3.jpg'),
              name: 'NAMTHONG NAN HOTEL',
              lab:'น่าน'
            },
            {
              img: require('@/assets/game/jiudian/1-4.jpg'),
              name: 'M2 HOTEL',
              lab:'พะเยา'
            },
            {
              img: require('@/assets/game/jiudian/1-5.jpg'),
              name: 'LMPERIAL MAE HONG',
              lab:'แม่ฮ่องสอน'
            },
            {
              img: require('@/assets/game/jiudian/1-6.jpg'),
              name: 'HOTEL LAMPANG',
              lab:'ลำปาง'
            },
            {
              img: require('@/assets/game/jiudian/1-7.jpg'),
              name: 'SIRI HOTEL',
              lab:'ลำพูน'
            },
            {
              img: require('@/assets/game/jiudian/1-9.jpg'),
              name: 'TON THONG RESORT',
              lab:'อุตรดิตถ์'
            },
            {
              img: require('@/assets/game/jiudian/1-10.jpg'),
              name: 'NORTH LNN TOWN ',
              lab:'เชียงใหม่'
            },
            {
              img: require('@/assets/game/jiudian/1-11.jpg'),
              name: 'Huernnana Boutique Hotel',
              lab:'แพร่'
            },
            {
              img: require('@/assets/game/jiudian/1-12.jpg'),
              name: 'Look at Home',
              lab:'ลำพูน'
            },
            {
              img: require('@/assets/game/jiudian/1-13.jpg'),
              name: 'Lung  Soi Ngoen Homestay',
              lab:'แม่ฮ่องสอน'
            }
          ]
        },

        {
          title: 'ภาคกลาง',
          list: [
            {
              img: require('@/assets/game/jiudian/2-1.jpg'),
              name: 'HOTEL CLOVER ASOKE',
              lab:'Bangkok'
            },
            {
              img: require('@/assets/game/jiudian/2-2.jpg'),
              name: 'THR MUSEUM HOTEL',
              lab:'นครปฐม'
            },
            {
              img: require('@/assets/game/jiudian/2-3.jpg'),
              name: 'PHAMONTRA',
              lab:'นครนายก'
            },
            {
              img: require('@/assets/game/jiudian/2-4.jpg'),
              name: 'OAKWOOD SUITES ',
              lab:'นนทบุรี'
            },
            {
              img: require('@/assets/game/jiudian/2-5.jpg'),
              name: '12 THE RESIDENCE',
              lab:'ปทุมธานี'
            },
            {
              img: require('@/assets/game/jiudian/2-6.jpg'),
              name: 'IUDIA',
              lab:'พระนครศรีอยุธยา'
            },
            {
              img: require('@/assets/game/jiudian/2-7.jpg'),
              name: 'FORTUNE D HOTEL',
              lab:'พิษณุโลก'
            },
            {
              img: require('@/assets/game/jiudian/2-8.jpg'),
              name: '(FEUNGFAH LITZ)',
              lab:'เพชรบูรณ์'
            },
            {
              img: require('@/assets/game/jiudian/2-9.jpg'),
              name: 'GlAI GAN PLACE HOTEL',
              lab:'สระบุรี'
            },
            {
              img: require('@/assets/game/jiudian/2-10.jpg'),
              name: '(VASIDTEE CITY HOTEL)',
              lab:'สุพรรณบุรี'
            },
            {
              img: require('@/assets/game/jiudian/2-11.jpg'),
              name: 'Eden House Resort',
              lab:'นครนายก'
            },
            {
              img: require('@/assets/game/jiudian/2-12.jpg'),
              name: 'Napa Hostel Samrong Station',
              lab:'จังหวัดสมุทรปราการ'
            }
          ]
        },
        {
          title: 'ภาคใต้',
          list: [
            {
              img: require('@/assets/game/jiudian/3-1.jpg'),
              name: 'GRAND FORTUNE HOTEL',
              lab:'นครศรีธรรมราช'
            },
            {
              img: require('@/assets/game/jiudian/3-2.jpg'),
              name: 'GARDENS HOTEL',
              lab:'ชุมพร'
            },
            {
              img: require('@/assets/game/jiudian/3-3.jpg'),
              name: '(ECOLOFT HOTEL)',
              lab:'ภูเก็ต'
            },
            {
              img: require('@/assets/game/jiudian/3-4.jpg'),
              name: 'THE FLORA MAY RESORT',
              lab:'สงขลา'
            },
            {
              img: require('@/assets/game/jiudian/3-5.jpg'),
              name: '(CBD 2 HOTEL)',
              lab:'สุราษฎร์ธานี'
            },
            {
              img: require('@/assets/game/jiudian/3-6.jpg'),
              name: 'DUSIT PRINCESS',
              lab:'พัทลุง'
            },
            {
              img: require('@/assets/game/jiudian/3-7.jpg'),
              name: 'CHANG VIEW RESORT',
              lab:'พังงา'
            },
            {
              img: require('@/assets/game/jiudian/3-8.jpg'),
              name: "(JE T'AIME HOTEL)",
              lab:'ตรัง'
            },
            {
              img: require('@/assets/game/jiudian/3-9.jpg'),
              name: '(BOUTIQUE RESORT)',
              lab:'สตูล'
            },
            {
              img: require('@/assets/game/jiudian/3-10.jpg'),
              name: 'MOON LAKE RESORT',
              lab:'ยะลา'
            },
            {
              img: require('@/assets/game/jiudian/3-11.jpg'),
              name: 'Xent Pool Villa',
              lab:'ระนอง'
            },
            {
              img: require('@/assets/game/jiudian/3-12.jpg'),
              name: 'Sleep whale',
              lab:'กระบี่'
            }
          ]
        },
        {
          title: 'ตะวันออก',
          list: [
            {
              img: require('@/assets/game/jiudian/4-1.jpg'),
              name: 'KASEMSARN HOTEL',
              lab:'จันทบุรี'
            },
            {
              img: require('@/assets/game/jiudian/4-2.jpg'),
              name: 'AVADA HOTEL',
              lab:'ตราด'
            },
            {
              img: require('@/assets/game/jiudian/4-3.jpg'),
              name: 'AVADA HOTEL',
              lab:'สระแก้ว'
            },
            {
              img: require('@/assets/game/jiudian/4-4.jpg'),
              name: 'GRANDE CENTRE POINT',
              lab:'พัทยา'
            },
            {
              img: require('@/assets/game/jiudian/4-5.jpg'),
              name: 'ANDAZ JOMTIEN BEACH ',
              lab:'พัทยา'
            },
            {
              img: require('@/assets/game/jiudian/4-6.jpg'),
              name: 'GOLDEN HOUSE SA KAEO',
              lab:'สระแก้ว'
            },
            {
              img: require('@/assets/game/jiudian/4-7.jpg'),
              name: "(THE BED'S 304)",
              lab:'ปราจีนบุรี'
            },
            {
              img: require('@/assets/game/jiudian/4-8.jpg'),
              name: 'HEAVEN HOTEL',
              lab:'ฉะเซิงเทรา'
            },
            {
              img: require('@/assets/game/jiudian/4-9.jpg'),
              name: 'LOTUS PANG SUAN',
              lab:' '
            },
            {
              img: require('@/assets/game/jiudian/4-10.jpg'),
              name: 'LOTUS PANG SUAN',
              lab:'ระ﻿ยอง'
            },
            {
              img: require('@/assets/game/jiudian/4-11.jpg'),
              name: 'The MuG Resor',
              lab:'ชลบุรี'
            },
            {
              img: require('@/assets/game/jiudian/4-12.jpg'),
              name: 'Baan Pansuk Resort',
              lab:'จันทบุรี'
            }
          ]
        },
        {
          title: 'ตะวันออกเฉียงเหนือ/อีสาน',
          list: [
            {
              img: require('@/assets/game/jiudian/5-1.jpg'),
              name: 'AMANTA HOTEL ',
              lab:'ห﻿นองคาย'
            },
            {
              img: require('@/assets/game/jiudian/5-2.jpg'),
              name: 'LAKE HOUSE',
              lab:'บึงกาฬ'
            },
            {
              img: require('@/assets/game/jiudian/5-3.jpg'),
              name: 'THE MODEL GARDEN',
              lab:'ยโ﻿สธร'
            },
            {
              img: require('@/assets/game/jiudian/5-4.jpg'),
              name: 'THE ROOM BOUTIQUE',
              lab:'ส﻿กลนคร'
            },
            {
              img: require('@/assets/game/jiudian/5-5.jpg'),
              name: 'SISAKET PONTAWIN',
              lab:'ศรีสะเ﻿กษ'
            },
            {
              img: require('@/assets/game/jiudian/5-6.jpg'),
              name: 'LOEI VILLAG',
              lab:'เลย'
            },
            {
              img: require('@/assets/game/jiudian/5-7.jpg'),
              name: 'RAMIARNDAO BOUTIQUE',
              lab:'อำนาจเจริญ'
            },
            {
              img: require('@/assets/game/jiudian/5-8.jpg'),
              name: 'BAAN HOME RESORT',
              lab:'กาฬสิน'
            },
            {
              img: require('@/assets/game/jiudian/5-9.jpg'),
              name: 'CENTA KORAT',
              lab:'โคราช'
            },
            {
              img: require('@/assets/game/jiudian/5-10.jpg'),
              name: 'CENTARA UBON',
              lab:'อุบลราชธานี'
            },
            {
              img: require('@/assets/game/jiudian/5-11.jpg'),
              name: 'THE WOOD',
              lab:'สุรินทร์'
            },
            {
              img: require('@/assets/game/jiudian/5-12.jpg'),
              name: 'ONE DD HOMES',
              lab:'อุดรธานี'
            }
          ]
        }
      ]
    };
  },
  methods: {
    toVip(index,name) {
      this.$router.push({ path: '/vip_detail?id=' + index+"&name="+name })
    },
    onRefresh() {
      setTimeout(() => {
        Toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
    tuUrl(index){
      if(index == 4){
        window.open('https://pornhubapparel.com')
      }else{
      this.lotteryitemIndex = index

      }
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
      }
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res => {
        this.gameList = res.data;
        this.getLotteryInfo()
        this.getLotteryPeilv()
      })
    },
    onChange(index) {
      this.$http({
        method: 'get',
        data: { class: index },
        url: 'lottery_list'
      }).then(res => {
        this.gameitem = res.data;
      })
    },
    getLotteryPeilv() {
      this.$http({
        method: 'get',
        data: { id: this.gameList[0].id },
        url: 'lottery_get_peilv'
      }).then(res => {
        if (res.code === 200) {
          this.lottery_peilv_list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    choosePlay(type, name) {
      if (this.choose[type] === true) {
        this.choose[type] = false;
        for (var i = 0; i < this.formData.length; i++) {
          if (type === this.formData[i]['type']) {
            this.formData.splice(i, 1)
          }
        }
      } else if (this.choose[type] === false) {
        this.formData.push({ 'name': name, 'type': type })
        this.choose[type] = true;
      }
      if (this.formData.length === 1) {
        this.shopping = true;
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.formData[j]['name'];
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.formData[j]['name'];
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = this.$t("reservation.no_choose");
        this.gameitem = "";
        this.shopping = false;
      }

    },

    check() {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.getUserInfo();
        this.getLotteryInfo();
      }
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    openImg(url){
      console.log(url)
      ImagePreview([
        url
]);
    },
    getLotteryInfo() {
      this.$http({
        method: 'get',
        data: { key: this.gameList[0].key },
        url: 'lottery_get_info'
      }).then(res => {
        if (res.code === 200) {
          // if(parseFloat(this.userInfo.money) < parseFloat(res.data.condition)){
          //   this.$toast(this.$t("reservation.contact_admin"));
          //   this.$router.push({path:'/Home'})
          //   return false;
          // }
          this.lottery = res.data;
          this.time = res.data.second * 1000;

          if (this.time / 1000 === 59) {
            this.$toast(this.$t("reservation.prize_succ") + this.lottery.now_expect);
          }
        } else if (res.code === 401) {
          this.$toast({
            message: res.msg,
            duration: 3000
          });
        }
      })
      // getLotteryItem(){
      //   this.$http({
      //     method: 'get',
      //     url: 'lottery_class'
      //   }).then(res=>{
      //     this.lotteryitem = res.data;
      //   })
      // }
    },
    doSub() {
      if (this.money === "0") {
        this.$toast(this.$t("reservation.money_err"));
        return false;
      }
      if (this.formData.length === 0) {
        this.$toast(this.$t("reservation.choose_num"));
        return false;
      } else if (this.money === "") {
        this.$toast(this.$t("reservation.price_place"));
        return false;
      } else {
        if (this.userInfo.money - (this.money * this.formData.length) < 0) {
          this.$toast(this.$t("reservation.balance_enough"));
          return false;
        } else {
          if (this.isDisabled) return
          this.isDisabled = true
          this.$http({
            method: 'post',
            data: {
              item: this.gameitem,
              money: this.money,
              lid: this.lottery.id,
              mid: this.userInfo.id,
              expect: this.lottery.now_expect
            },
            url: 'game_place_order'
          }).then(res => {
            if (res.code === 200) {
              this.$toast(res.msg);
              this.allClear();
              this.getUserInfo();
            } else if (res.code === 401) {
              this.$toast(res.msg);
            }
            this.isDisabled = false
          })
          return true;
        }
      }
    },

    allClear() {
      for (var i = 0; i < this.formData.length; i++) {
        this.choose[this.formData[i]['type']] = false;
      }
      this.formData.length = 0;
      this.money = "";
      this.shopchoose = this.$t("reservation.no_choose");
      this.gameitem = "";
      this.shopping = false;
    },
  },
  created() {
    this.getGameItem();//获取首页游戏列表
    // this.getLotteryItem();
    this.getUserInfo();
  }
};
</script>

<style lang='less' scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
}

.nav-bar {
  background: #000;
  height: 100px;

}

.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

.van-sidebar {
  width: 180px;
}

.van-sidebar-item--select::before {
  left: 10px;
  height: 44%;
  background-color: #EE8B35;
  border-radius: 5px;
  width: 10px;
}

.van-sidebar-item--select {
  color: #EE8B35;
  font-size: 35px;
  text-align: center;
}

/deep/ .van-sidebar-item__text {
  width: 140px;
  margin-left: -25px;
}

.van-sidebar-item {
  font-size: 30px;
  text-align: center;
  padding: 50px;
  color: #fff;
  background-color: #ffffff;
}

.van-sidebar-item--select,
.van-sidebar-item--select:active {
  background-color: #000;
  ;

  color: #EE8B35;
}

.convention-item {
  display: flex;
  align-items: center;
  height: calc(100% - 15px);
}

.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}

.convention-item .left {
  height: 100%;
  background-color: #000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.convention-item .right {
  height: 100%;
  flex: 1;
  background-color: #000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 80px;
}

.convention-item .right .list-wrapper {
  padding: 20px 20px;
  min-height: 800px;
}

.convention-item .right .list-wrapper .game_item_img {
  width: 200px;
  height: 200px;
}

.convention-item .right .list-wrapper span {
  margin-top: 10px;
  font-size: 30px;
  color: #fff;
}

.convention-item .right .list-wrapper span:last-child {
  margin-top: 10px;
  font-size: 24px;
  color: #fff;
}

.van-grid-item {
  padding: 10px;

}

::v-deep .van-grid-item__content--center {
  border-radius: 15px;
}

::v-deep .van-image__img {
  border-radius: 40px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.me-sidebar {
  width: 120px;
  padding: 0px 10px;
  box-sizing: border-box;
}

.me-sidebar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px 10px;
  margin-bottom: 4px;
}

.me-sidebar-item-active {
  color: #000;
  background: #C97D32;
  border: 1px solid #C97D32;
}

.inpContent {
  background: #000;
  padding: 20px;
  margin: 20px;
}

.me-sidebar-item-img {
  width: 60px;
  height: 60px;
  margin-bottom: 4px;
}

.me-sidebar-item-name {
  font-size: 20px;
}

.game-group {
  background: #222222;
  color: #fff;
  padding: 20px 10px 30px;
  margin: 0 10px;
  border-radius: 20px;
  border: 1px solid #707070;
  margin-top: 10px;
  margin-bottom: 30px;
}

.game-group-title {
  font-size: 32px;
  margin-bottom: 10px;
  text-align: center;
}

.game-group-content {
  font-size: 18px;
}



.record {
  // background-color: #fff;
  // box-shadow: 0 2px 2px 0 #cacaca;
  z-index: 1;
  height: 100%;
}

.record .period {
  display: flex;
  align-items: center;
  padding: 20px 0;
  padding-left: 20px;
  padding-right: 20px;
}

.record .period .cover {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}

.record .period .period-number {
  flex: 1;
  margin-right: 10px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
}

.van-count-down {
  color: #ff253f;
  font-size: 24px;
  margin-top: 10px;
  float: right;
}

.linear-gradient {
  width: 100%;
  height: 2px;
}

.record .recent {
  display: flex;
  align-items: center;
  height: 110px;
}

.sumValueTwoSides {
  display: flex;
  padding: 30px 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.rectangle {
  overflow: hidden;
}

.rectangle.large {
  margin: 0 0 30px 20px;
  width: 45%;
  border-radius: 10px;
  background: #000;
}

.rectangle .wrapper {
  position: relative;
  padding: 0 10px;
  background: #fff;
}

.rectangle .wrapper .content {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.rectangle.large .wrapper {
  padding-bottom: 50%;
}

.rectangle .wrapper .content .name-text.large {
  font-size: 20px;
}

.rectangle .wrapper .content .name-text {
  color: #7d7c7c;
  font-weight: bolder;
}

.rectangle .wrapper .content .odd-text.large {
  font-size: 25px;
  // margin-top: -30px;
  margin-top: 0px;
}

.rectangle .wrapper .content .odd-text {
  text-align: center;
  color: #ff253f;
}

.rectangle.active .wrapper {
  // background-color: #ff253f !important;
}

.mask {
  background-color: rgb(0 0 0 / 0%);
  animation-duration: 0.35s;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.name-text.large {
  font-size: 20px;
}

.name-text {
  color: #fff;
  font-weight: bolder;
}

.checkedContent {
  background: #EE8B35;
  height: 100%;
    flex-direction: column;
    display: -webkit-flex;
    display: flex;
    padding-bottom: 110px;
}

.item.sub-btn {
  background: #EE8B35;
  color: #fff;
  width: 100%;
  border: 0;
  font-size: 28px;
  padding: 20px;
  height: auto !important;
}

.amount-wrapper-inp {
  padding: 20px;
  font-size: 26px;
  margin-bottom: 20px;
}
::v-deep.van-grid-item .van-grid-item__content {
  
  color: #fff;
  background: none;
}

/deep/.VipList .van-grid-item .van-grid-item__content {
  height: 200px;
  font-size: 32px;
  color: #fff;
}

/deep/.VipList .van-grid-item:nth-child(1) .van-grid-item__content {
  background: #B2B2B2;
}

/deep/.VipList .van-grid-item:nth-child(2) .van-grid-item__content {
  background: #F1D78E;
}

/deep/.VipList .van-grid-item:nth-child(3) .van-grid-item__content {
  background: #1D3745;
}

/deep/.VipList .van-grid-item:nth-child(4) .van-grid-item__content {
  background: #470838;
}

.phone_btn {
  background: #2D2D2D;
  color: #fff;
  text-align: center;
  height: 80px;
  line-height: 80px;
  border-radius: 10px;
  margin: 20px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 32px;
}
.phone_btn img{
  position: absolute;
  top: 50%;
  left: 60px;
  transform: translate(0,-50%);
}
.jiud_title{
  font-size: 32px;
  color: #fff;
  text-align: center;
  margin: 20px 0;
}
.qrcode_txt{
  color: #fff;
  font-size: 40px;
  text-align: center;
  margin-top: 10px;
}

</style>
